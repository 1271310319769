import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
  PropsWithChildren,
} from 'react';
import { useRouter } from 'next/router';
import { createListingParams } from 'contexts/preferences/listing-params';
import { createSchoolParams } from 'contexts/preferences/school';
import storage from 'utils/storage';
import Cookies from 'js-cookie';
import { isTrustarcCookieTypeAllowed } from 'utils/trustarc-cookies';
import {
  DEFAULT_EXPIRE_DAYS,
  getLastSearchLocationTag,
  getRecentSearchesTag,
} from 'constants/cookies';
import {
  ACTIVECAMPAIGN_SCRIPT,
  GTM_SCRIPT,
  NEWRELIC_SCRIPT,
} from 'constants/HTMLAttributes';
import { useUserContext, useThemeContext } from 'contexts';
import { getUserLastSearch } from 'pages/api/last-search/lastSearchApiClient';
import { checkLocationHasAreaPageWithId } from 'data/search-predictions';
import { findLocationBoundaryByPlaceId } from 'utils/google-maps/geoLocator';
import { AREA_LISTINGS_ROUTE, getRouteNameFromPath } from 'components/dynamic-page/route-matchers';
import { updateSchoolParamsCookie } from './school';
import { ThemeNames } from 'types/themes';
import { cloneDeep } from 'lodash';
import deepmerge from 'deepmerge';
import { Prettify } from 'types/prettify';
import type SearchPrediction from 'data/search-predictions';

import type { ListingParams, ListingParamsMethods } from 'contexts/preferences/listing-params/types';
import type { SchoolParams as TSchoolParams } from 'contexts/preferences/school/types';
import type SchoolParams from 'contexts/preferences/school';
import type { Screen } from 'utils/types';

export interface LastSearchLocation extends SearchPrediction {
  boundary: string | null;
}

export type GlobalFilterPreferences = {
  listingParams: ListingParams & ListingParamsMethods; // FIXME: listingParams updates when saved.
}

export type IPreferences = Prettify<
{
  schoolParams: SchoolParams;
  updateSchoolParams: (params: Partial<SchoolParams>) => void;
  screen?: Screen;
  recentSearches: SearchPrediction[] | undefined;
  lastSearchLocation?: LastSearchLocation;
  addToRecentSearches: (searchPrediction: SearchPrediction) => void;
  areFunctionalCookiesEnabled: boolean;
  isMapSearchOpen: boolean;
  setIsMapSearchOpen: (isOpen: boolean) => void;
}
& GlobalFilterPreferences>;

type PreferencesContextProps = PropsWithChildren<{
  listingParamsData: ListingParams;
  schoolParamsData: TSchoolParams;
  screen?: Screen;
  functionalCookiesEnabled: boolean;
  advertisingCookiesEnabled: boolean;
  lastSearchLocationCookie?: LastSearchLocation;
}>;

export const PreferencesContext = createContext<IPreferences | Record<string, unknown>>({});

export function usePreferencesContext <T extends IPreferences | Record<string, unknown> = IPreferences>() {
  return useContext(PreferencesContext) as T;
}

export default function PreferencesContextProvider({ listingParamsData, schoolParamsData, screen, functionalCookiesEnabled, advertisingCookiesEnabled, lastSearchLocationCookie, children }: PreferencesContextProps) {
  const { user } = useUserContext();
  const { themeName } = useThemeContext();
  const localStorageTag = getRecentSearchesTag(themeName as ThemeNames);
  const [listingParams, setListingParams] = useState(() => {
    if (user) {
      if (user.hideImagelessListings !== undefined && user.hideImagelessListings !== null) {
        const originalParams = cloneDeep(listingParamsData);
        originalParams.filter.hasImage = user.hideImagelessListings;
        return createListingParams(originalParams);
      }
    }
    return createListingParams(listingParamsData);
  });
  const [schoolParams, setSchoolParams] = useState(createSchoolParams(schoolParamsData));
  const [recentSearches, setRecentSearches] = useState(storage.get(localStorageTag));
  const [areFunctionalCookiesEnabled, setAreFunctionalCookiesEnabled] = useState(functionalCookiesEnabled);
  const [areAdvertisingCookiesEnabled, setAreAdvertisingCookiesEnabled] = useState(advertisingCookiesEnabled);
  const [lastSearchLocation, setLastSearchLocation] = useState<LastSearchLocation | undefined>(lastSearchLocationCookie);
  const router = useRouter();
  const isOnAreaPage = getRouteNameFromPath(router.asPath) === AREA_LISTINGS_ROUTE;
  const isOnSearchPage = router.asPath.startsWith('/search');
  const isExpUsTenant = themeName === ThemeNames.EXP_REALTY_US;
  const initialMapSearchOpen = router.query && !!router.query.openSearch && isExpUsTenant && isOnSearchPage;
  const [isMapSearchOpen, setIsMapSearchOpen] = useState(initialMapSearchOpen);
  const getUserLastSearchParams = useCallback(async () => {
    const hasUrlParams = !!window.location.search.length;
    const doesSearchHaveUrlParams = isOnSearchPage && hasUrlParams;
    if (user && !isOnAreaPage && !doesSearchHaveUrlParams) {
      const userLastSearchParams = await getUserLastSearch({ userId: user.id, sourceTheme: themeName });
      if (userLastSearchParams && 'lastSearch' in userLastSearchParams) {
        if (user) {
          if (user.hideImagelessListings !== undefined && user.hideImagelessListings !== null) {
            setListingParams(prev => {
              prev.setHasImage(user.hideImagelessListings);
              return createListingParams(prev);
            });
          }
        }
      }
    }
  }, [isOnSearchPage, user, isOnAreaPage, themeName]);

  const addToRecentSearches = useCallback(async (searchPrediction: SearchPrediction) => {
    // Saving new recent searches
    const localRecentSearches = storage.get(localStorageTag) as SearchPrediction[] | undefined;
    let newRecentSearches = localRecentSearches || [];
    if (localRecentSearches) {
      if (!localRecentSearches.some(search => search.description === searchPrediction.description)) {
        const maxNumberOfRecentSearchesShown = 12;
        if (localRecentSearches.length === maxNumberOfRecentSearchesShown) {
          localRecentSearches.pop();
        }
        newRecentSearches = [searchPrediction, ...localRecentSearches];
      }
    } else {
      newRecentSearches = [searchPrediction];
    }
    setRecentSearches(newRecentSearches);
    storage.set(localStorageTag, newRecentSearches);

    // Saving new last search if it's a location
    if (searchPrediction?.group === 'locations') {
      let newLastSearch = searchPrediction;
      if (!checkLocationHasAreaPageWithId(searchPrediction.id)) { // If not an area page location, save boundary so this can be used on homepage components to fetch listings
        const boundary = await findLocationBoundaryByPlaceId(searchPrediction.id);
        newLastSearch = { ...searchPrediction, boundary } as LastSearchLocation;
      }
      setLastSearchLocation(newLastSearch as LastSearchLocation);
      searchPrediction.group === 'locations' && Cookies.set(getLastSearchLocationTag(themeName as ThemeNames), JSON.stringify(newLastSearch), { expires: DEFAULT_EXPIRE_DAYS });
    }
  }, [localStorageTag, themeName]);

  const handleNewMessages = useCallback((e: MessageEvent) => {
    try {
      const data = JSON.parse(e.data);

      // Trustarc consent messages
      if (data?.source === 'preference_manager' && data?.message === 'submit_preferences') {
        setAreFunctionalCookiesEnabled(isTrustarcCookieTypeAllowed({ cookiePreference: data?.data?.value, type: 'functional' }));
        setAreAdvertisingCookiesEnabled(isTrustarcCookieTypeAllowed({ cookiePreference: data?.data?.value, type: 'advertising' }));
      }
    // eslint-disable-next-line no-empty
    } catch (e) {}
  }, []);

  useEffect(() => {
    window.addEventListener('message', handleNewMessages, { passive: true });
    return () => {
      window.removeEventListener('message', handleNewMessages);
    };
  }, [handleNewMessages]);

  useEffect(() => {
    if (router.query && !!router.query.openSearch && isExpUsTenant) {
      setIsMapSearchOpen(true);
    }
  }, [isExpUsTenant, router.query]);

  useEffect(() => {
    getUserLastSearchParams();
  }, [getUserLastSearchParams, user]);

  useEffect(() => {
    // Remove functional scripts
    if (!areFunctionalCookiesEnabled) {
      document.getElementById(ACTIVECAMPAIGN_SCRIPT)?.remove();
      document.getElementById(GTM_SCRIPT)?.remove();
      document.getElementById(NEWRELIC_SCRIPT)?.remove();
    }
  }, [areFunctionalCookiesEnabled, areAdvertisingCookiesEnabled]);

  const updateSchoolParams = useCallback((params: SchoolParams) => {
    updateSchoolParamsCookie(params);
    setSchoolParams(createSchoolParams(params));
  }, []);

  const contextValue = useMemo(() => ({
    listingParams,
    schoolParams,
    screen,
    recentSearches,
    lastSearchLocation,
    areFunctionalCookiesEnabled,
    isMapSearchOpen,
    addToRecentSearches,
    updateSchoolParams,
    setIsMapSearchOpen,
  }), [
    listingParams,
    schoolParams,
    screen,
    recentSearches,
    lastSearchLocation,
    areFunctionalCookiesEnabled,
    isMapSearchOpen,
    addToRecentSearches,
    updateSchoolParams,
    setIsMapSearchOpen,
  ]);

  return (
    <PreferencesContext.Provider value={contextValue}>
      {children}
    </PreferencesContext.Provider>
  );
}

